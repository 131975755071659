* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
}

h1 {
  color: #ffa500;
  font-weight: bold;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

/* Button styles */
.btn {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: pulse 1s ease-in-out infinite;
}

.filled-btn {
  background-color: #ffa500;
  border: 2px solid #ffa500;
  color: #000;
  padding: 10px 20px;
  border-radius: 10px 0px 0px 10px;
}

.filled-btn:hover {
  background-color: #ff8c00;
  transform: scale(1.05);
}

.outline-btn {
  background-color: transparent;
  border: 2px solid #ffa500;
  color: #ffa500;
  padding: 10px 20px;
  border-radius: 0px 10px 10px 0px;
}

.outline-btn:hover {
  background-color: #ffa500;
  color: #000;
}
.cus-btn {
  background-color: inherit;
  border: 1px solid #000;
  color: #000;
}
.cus-btn:hover {
  background-color: #ffa500;
  border: 1px solid #ffa500;
  color: #000;
}
.btn:active {
  transform: scale(0.95);
}
.navbar {
  background-color: #333; /* Adjust background color as needed */
  color: #fff;
  position: fixed; /* Fixes the navbar to the top */
  top: 0; /* Sticks it to the top of the viewport */
  width: 100%; /* Full width */
  z-index: 1000; /* Ensure it appears above other content */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
}
.navbar-brand {
  color: #f3f3f3;
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 5%;
}

.nav-link {
  color: #f3f3f3;
  margin: 0 15px;
  transition: all 0.3s ease;
}

.nav-link:hover {
  color: #ff6b6b;
  transform: translateY(-2px);
}

.navbar-toggler {
  display: none;
  background: none;
  border: none;
  color: #ffffff;
}

/* Hero section styling  */
.hero-section {
  position: relative;
  height: 60vh;
  width: 100%;
  max-width: 95%;
  margin: 5rem auto;
  border-radius: 20px;
  overflow: hidden;
}

video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.content {
  position: absolute;
  text-align: center;
  color: #ffffff;
  width: 100%;
}

.content h1 {
  font-size: 10rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.content p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

/* Section styles */
.section-title {
  color: #ffa500;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.section-subtitle {
  color: #333333;
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

/* Service card styles */
.service-card {
  background-color: #fff;
  padding: 2rem;
  border-radius: 20px;
  margin: 1.5rem;
  transition: all 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Sub-hero section styles */
.Sub-hero-section {
  background-size: cover;
  max-width: 95%;
  height: 50vh;
  color: white;
  margin: 5rem auto;
  border-radius: 20px;
  overflow: hidden;
}

.Sub-hero-section h1 {
  text-align: center;
  color: white;
  font-size: 60px;
  padding: 10px;
}

/* Typography */
h2 {
  font-size: 2rem;
}

h5 {
  font-size: 1.5rem;
}

h6 {
  font-size: 1.25rem;
}
.bg-Unleashing {
  background-image: url(./bgin.jpg);
  background-size: cover;
}
.custom-cta-btn {
  background-color: #ffa500;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.custom-cta-btn:hover {
  background-color: inherit;
  border: 2px solid #ffa500;
  color: #ffa500;
  font-weight: bold;
  transform: scale(1.1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.carousel-indicators {
  margin-bottom: -50px;
}
/* Responsive styles */
@media (max-width: 1000px) {
  .navbar {
    flex-direction: row;
    align-items: flex-start;
  }

  .navbar-toggler {
    display: block;
    align-self: flex-end;
    margin: 7px;
    background-color: #ffa500;
  }

  .nav-item {
    width: 100%;
    margin: 0.5rem 0;
  }

  .content h1 {
    padding-top: 60px;
    font-size: 4rem;
  }

  .content p {
    font-size: 1rem;
  }

  .hero-section {
    height: 50vh;
    max-width: 100%;
    border-radius: 0;
    margin: 0;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: auto;
    z-index: -1;
    transform: translate(-50%, -50%);
  }
  .Sub-hero-section {
    border-radius: 0;
    max-width: 100%;
    margin-top: 0;
  }
  .Sub-hero-section h1 {
    font-size: 1.5rem;
    padding: 10px 20px;
    margin-bottom: 100px;
  }
  .cus-button {
    padding: 10px 1px;
    border: 2px solid #ffa500;
  }
  .filled-btn {
    background-color: #ffa500;
    color: #000;
    border-radius: 10px 0px 0px 10px;
  }

  .filled-btn:hover {
    background-color: #ff8c00;
    transform: scale(1.05);
  }

  .outline-btn {
    background-color: transparent;
    color: #ffa500;
    border-radius: 0px 10px 10px 0px;
  }

  .service-card {
    margin: 1rem 0;
  }

  .btn {
    width: 100%;
    margin: 0.5rem 0;
  }
}

.App {
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
}
